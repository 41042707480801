<main class="content">
  <div class="table-container">
    <div class="button-container" *ngIf="!emptyState">
      <div class="filter">
        <app-input-filter
          (filterChanged)="handleFilter($event)"
        ></app-input-filter>
      </div>
      <button mat-raised-button color="primary" (click)="handleNewEmployee()">
        Novo funcionário
      </button>
    </div>
    <div class="empty-message" *ngIf="emptyState">
      <app-empty-state
        [showMessage]="emptyState"
        title="Ocorreu um problema ao listar os funcionários"
        detail="Devido a instabilidade do servidor, ocorreu um problema ao listar, tente novamente!"
        buttonMessage="Tentar novamente"
        (buttonEvent)="handleTryAgain()"
      ></app-empty-state>
    </div>
    <table
      mat-table
      [dataSource]="employees"
      class="mat-elevation-z8"
      *ngIf="!emptyState"
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Código</th>
        <td id="id" mat-cell *matCellDef="let employee">{{ employee.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td id="name" mat-cell *matCellDef="let employee">
          {{ employee.employee_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="salary">
        <th mat-header-cell *matHeaderCellDef>Salário</th>
        <td id="salary" mat-cell *matCellDef="let employee">
          {{ employee.employee_salary }}
        </td>
      </ng-container>

      <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef>Idade</th>
        <td id="age" mat-cell *matCellDef="let employee">
          {{ employee.employee_age }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td id="actions" mat-cell *matCellDef="let employee">
          <button
            mat-mini-fab
            color="primary"
            aria-label="Example icon button with a menu icon"
            (click)="handleEdit(employee)"
          >
            <span class="icon-link material-icons" style="padding: 0">
              mode_edit
            </span>
          </button>
          <button
            id="delete"
            mat-mini-fab
            color="warn"
            aria-label="Example icon button with a filter list icon"
            style="margin-left: 1em; max-height: 40px"
            (click)="openDialog(employee)"
          >
            <span class="icon-link material-icons"> delete </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="totalRecord"
      [pageSize]="10"
      *ngIf="!hasFilter && !emptyState"
      (page)="handlePageChange($event)"
    >
    </mat-paginator>
  </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
</main>
