<header>
  <nav>
    <ul>
      <li><a routerLink="/list">Listagem</a></li>
      <li><a routerLink="/form">Novo funcionário</a></li>
    </ul>
  </nav>
</header>

<main>
  <ng-content></ng-content>
</main>
