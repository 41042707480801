<form [formGroup]="inputForm">
  <div class="input-container">
    <mat-form-field class="full-width">
      <input matInput formControlName="filter" style="width: 100%" />
    </mat-form-field>
    <button
      mat-mini-fab
      [color]="hasFilter ? 'basic' : 'primary'"
      aria-label="Example icon button with a filter list icon"
      style="margin-left: 1em"
      (click)="handleSearch()"
    >
      <span class="icon-link material-icons">
        {{ hasFilter ? 'close' : 'search'}}
      </span>
    </button>
  </div>
</form>
